
export function downloadExcel (listData, header, fields, filename) {
  return import('@/vendor/Export2Excel').then(excel => {
    const data = formatJson(fields, listData)
    excel.export_json_to_excel({
      header,
      data,
      filename,
      bookType: 'xlsx'
    })
    return true
  })
}

const formatJson = (filterVal, jsonData) => {
  return jsonData.map(v => filterVal.map(j => {
    return v[j]
  }))
}
