<template>
  <Layout
    class="ElectronicCommerce"
  >
    <BHeader>
      <template #right>
        <el-button
          type="prrmary"
          :disabled="radio == 2"
          @click="handleCreateTag"
        >
          新增商品
        </el-button>
      </template>
    </BHeader>

    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%;margin-top:10px"
    >
      <el-table-column
        label="商品编号"
        prop="id"
      />
      <el-table-column
        label="商品名称"
        prop="goods_name"
      />
      <el-table-column
        label="数量"
        prop="num"
      />
      <el-table-column
        label="价格(points)"
        prop="price"
      />
      <el-table-column
        label="折扣(%) off"
        prop="discount"
      />
      <el-table-column
        min-width="120"
        label="剩余/限购"
      >
        <template v-slot="{row}">
          {{ row.quota_num-row.used_num }} / {{ row.quota_num }}
        </template>
      </el-table-column>
      <el-table-column
        label="奖品图片"
        min-width="180px"
      >
        <template v-slot="{row}">
          <el-image
            style="width: 160px;height:160px"
            class="game-cover"
            fit="contain"
            :src="row.goods_img"
            lazy
          />
        </template>
      </el-table-column>
      <el-table-column
        label="商品描述"
        prop="goods_desc"
      />
      <el-table-column
        prop="goods_status"
        min-width="120"
        label="上架状态"
      >
        <template v-slot="{row}">
          {{ row.goods_status===1?'已上架':'已下架' }}
        </template>
      </el-table-column>

      <el-table-column
        label="剩余CDK"
        prop="CDKinventory"
      />
      <el-table-column
        label="操作"
      >
        <template #default="{row}">
          <BTextButton
            :disabled="radio == 2"
            title="编辑"
            icon="fa fa-pencil"
            @click="handleEditItem(row)"
          />
          <el-button
            v-if="row.exchange_type === 2"
            type="text"
            @click="openBatchUpload(row)"
          >
            补充库存
          </el-button>
          <BTextButton
            :disabled="radio == 2"
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />

    <el-dialog
      :title="dialogTile"
      :visible.sync="showDialog"
      width="600px"
      @close="handleDialogClose"
    >
      <el-form
        ref="subForm"
        v-loading="showDialogLoading"
        :model="subForm"
        :rules="subFormRules"
        label-width="100px"
      >
        <el-form-item
          label="商品名称"
          prop="goodsName"
        >
          <el-input
            v-model="subForm.goodsName"
            style="width: 200px;"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="商品类型"
          prop="goodsType"
        >
          <el-select
            v-model="subForm.goodsType"
            placeholder="选择类型"
          >
            <el-option
              v-for="item in descTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品选择"
          prop="type"
        >
          <el-select
            v-model="subForm.type"
            placeholder="选择商品"
          >
            <el-option
              v-for="item in CompanyList"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="商品数量"
          prop="num"
        >
          <el-input
            v-model="subForm.num"
            style="width: 200px;"
            show-word-limit
            type="number"
          />
          <span v-if="subForm.type">
            {{ CompanyList[subForm.type-1].company }}
          </span>
          <!-- <span
            v-if="subForm.type === 2"
            style="margin-left:15px"
          >单位: 小时</span>
          <span
            v-else
            style="margin-left:15px"
          >单位: 个</span> -->
        </el-form-item>
        <el-form-item
          label="限购数量"
          prop="quotaNum"
        >
          <el-input
            v-model="subForm.quotaNum"
            style="width: 200px;"
            show-word-limit
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="价格"
          prop="price"
        >
          <el-input
            v-model="subForm.price"
            style="width: 200px;"
            show-word-limit
            type="number"
          />
        </el-form-item>
        <el-form-item
          label="商品状态"
          prop="goodsStatus"
        >
          <el-select
            v-model="subForm.goodsStatus"
            placeholder="选择状态"
          >
            <el-option
              v-for="item in goodsStatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="兑换方式"
          prop="exchangeType"
        >
          <el-select
            v-model="subForm.exchangeType"
            placeholder="选择状态"
          >
            <el-option
              v-for="item in exchangeTypeList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="折扣比例"
          prop="discount"
        >
          <el-input
            v-model="subForm.discount"
            style="width: 200px;"
            show-word-limit
            type="number"
          /> % off
        </el-form-item>
        <el-form-item
          label="上传图片："
          prop="swiper"
        >
          <UploadImage
            :limit="1"
            :file-list.sync="swiperList"
          />
        </el-form-item>
        <el-form-item
          label="商品描述"
          prop="goodsDesc"
        >
          <el-input
            v-model="subForm.goodsDesc"
            style="width: 200px;"
            show-word-limit
          />
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleConfirm"
        >确 定</el-button>
      </span>
    </el-dialog>
    <!--    批量上传-->
    <el-dialog
      :visible.sync="batchUploadVisible"
      width="500px"
      @closed="loadedList = []"
    >
      <div class="batchUpload">
        <div class="icon-wrapper">
          <i class="el-icon-upload upload-icon" />
        </div>
        <template v-if="!loadedList.length">
          <div class="batchUpload-tips">
            <uploadExcel
              text="上传数据，仅支持模板格式的excel"
              @uploaded="handleUploadExcel"
            />
          </div>
          <div>
            <el-button
              type="text"
              @click="downloadExcel"
            >
              下载模板
            </el-button>
          </div>
        </template>
        <template v-else>
          <p>读取到 <span style="color: blue;">{{ loadedList.length }}</span> 条数据，是否确认上传</p>
          <div class="batchUpload-ctrl-area">
            <uploadExcel
              text="重新上传"
              @uploaded="handleUploadExcel"
            />
            <el-button
              type="primary"
              @click="handlePostExcelData"
            >
              确定
            </el-button>
          </div>
        </template>
      </div>
    </el-dialog>
    <!--    批量上传-->
    <div />
  </Layout>
</template>

<script>
import { downloadExcel } from '@/components/UploadExcel/downloadExcel'
import uploadExcel from '@/components/UploadExcel/uploadExcel'
import {
  pointsExchangeGoodsList,
  savePointsMallGoods,
  addPointsMallCDK,
  pointsMallGoodsType
  // queryAllGameTagList,
  // queryGameTagGroupList,
  // deleteGameTag,
  // queryGameTagdetail,
  // // tagNameValidateCheck,
  // createNewTag,
  // updateGameTag
} from '@/api/checksetting.js'
export default {
  components: { uploadExcel },
  data () {
    const swiperValidator = (rule, value, callback) => {
      if (this.swiperList.length) {
        return callback()
      } else {
        callback(new Error('cover is required'))
      }
    }
    return {
      descTypeList: [{ name: '实物商品', value: 2 }, { name: '虚拟商品', value: 1 }],
      CompanyList: [],
      goodsStatusList: [{ name: '下架', value: 2 }, { name: '上架', value: 1 }],
      exchangeTypeList: [{ name: 'cdk兑换', value: 2 }, { name: '自动发送', value: 1 }],
      action: '',
      batchUploadTemplate: {
        list: [],
        header: ['CDKEY(必填)'],
        fields: ['cdkeys']
      },
      batchUploadVisible: false,
      currentRows: {},
      loadedList: [],
      radio: '1',
      inradio: '1',
      nextradio: '1',
      swiperList: [],
      tagTypeList: [],
      descTypeMap: {},
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      showDialog: false,
      dialogTile: '',
      subForm: {},
      subFormRules: {
        swiper: [{ required: true, validator: swiperValidator }],
        goodsName: [
          { required: true, message: '商品名称必填' }
        ],
        goodsType: [
          { required: true, message: '商品类型必选' }
        ],
        type: [
          { required: true, message: '请选择商品' }
        ],
        num: [
          { required: true, message: '请输入商品数量' }
        ],
        quotaNum: [
          { required: true, message: '请输入限购数量' }
        ],
        price: [
          { required: true, message: '请输入价格' }
        ],
        discount: [
          // { required: true, message: '请输入折扣' },
          { pattern: /^(0|100|[1-9]\d?)$/, message: '不能大于100' }
        ],
        goodsDesc: [
          { required: true, message: '请输入商品描述' }
        ],
        goodsStatus: [
          { required: true, message: '请选择商品状态' }
        ],
        exchangeType: [
          { required: true, message: '请选择商品兑换方式' }
        ]
      },
      showDialogLoading: false,
      updateLoading: false
    }
  },

  created () {
    this.handleSearch()
    // this.queryGameTagGroupList()
    pointsMallGoodsType({})
      .then(res => {
        if (res.code === 200) {
          this.CompanyList = res.data || []
        }
      })
  },
  methods: {
    handlePostExcelData () {
      const list = this.loadedList || []
      const goodsid = this.currentRows.id
      console.log({ list: list, goods_id: goodsid })
      addPointsMallCDK({ list: list, goods_id: goodsid })
        .then(res => {
          if (res.code === 200) {
            this.batchUploadVisible = false
            this.$message.success('导入成功')
            this.queryDataList()
          }
        })
    },
    downloadExcel () {
      const header = this.batchUploadTemplate.header
      const list = this.batchUploadTemplate.list
      const fields = this.batchUploadTemplate.fields
      downloadExcel(list, header, fields, 'CDKEY库存上传模板(填写数据从第二行开始, 请勿修改列名)')
    },
    uploadExcel (data) {
      const header = this.batchUploadTemplate.header
      const fields = this.batchUploadTemplate.fields
      const maps = header.reduce((result, field, index) => {
        result[field] = fields[index]
        return result
      }, {})
      return data.list.map(item => {
        const temp = {}
        Object.keys(item).forEach(field => {
          temp[maps[field]] = item[field]
        })
        return temp
      })
    },
    handleUploadExcel (data) {
      const result = this.uploadExcel(data) || []
      // check result list
      this.loadedList = result.map(item => {
        return item.cdkeys
      })
    },
    openBatchUpload (row) {
      this.batchUploadVisible = true
      this.currentRows = row
    },
    inputhandle ({ target }) {
      this.verificationVal = target.value = target.value.replace(/[^0-9]/g, '')
    },
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      this.listInfo.loading = true
      pointsExchangeGoodsList(this.formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    queryGameTagGroupList () {
      // queryGameTagGroupList({})
      //   .then(res => {
      //     if (res.code === 200) {
      //       this.tagTypeList = res.data.list || []
      //       this.descTypeMap = this.tagTypeList.reduce((result, item) => {
      //         result[item.parent.id] = item.childList || []
      //         return result
      //       }, {})
      //     }
      //   })
    },
    handleCreateTag () {
      this.dialogTile = '新增商品'
      this.swiperList = []
      this.showDialog = true
    },
    handleEditItem (row) {
      this.dialogTile = '编辑商品'
      this.showDialog = true
      pointsExchangeGoodsList({
        id: row.id
      })
        .then(res => {
          if (res.code === 200) {
            this.swiperList = []
            this.subForm = res.data.list[0]
            this.$set(this.subForm, 'goodsName', res.data.list[0].goods_name)
            this.$set(this.subForm, 'goodsType', res.data.list[0].goods_type)
            this.$set(this.subForm, 'quotaNum', res.data.list[0].quota_num)
            this.$set(this.subForm, 'goodsStatus', res.data.list[0].goods_status)
            this.$set(this.subForm, 'goodsDesc', res.data.list[0].goods_desc)
            this.$set(this.subForm, 'exchangeType', res.data.list[0].exchange_type)
            this.$set(this.subForm, 'type', res.data.list[0].type + '')
            this.swiperList.push({ url: this.subForm.goods_img })
          }
        })
        .catch(() => {
          this.showDialog = false
        })
        .finally(() => {
          this.showDialogLoading = false
        })
    },
    handleDeleteItem (row) {
      this.$confirm('此操作将删除该商品, 是否继续', '提示')
        .then(() => {
          this.$set(row, 'deleteLoading', true)
          savePointsMallGoods({
            action: 'del',
            detail: { id: row.id, goodsStatus: 3 }
          })
            .then(res => {
              if (res.code === 200) {
                this.queryDataList()
                this.$message.success('删除成功')
                this.showDialog = false
              }
            })
            .catch(() => {
              this.showDialog = false
            })
            .finally(() => {
              this.updateLoading = false
              this.showDialogLoading = false
            })
        })
    },
    handleDialogClose () {
      this.subForm = {}
      this.$refs.subForm.resetFields()
    },
    handleConfirm () {
      this.$refs.subForm.validate(valid => {
        if (valid) {
          this.subForm.goodsImg = this.swiperList[0].url
          // console.log(this.swiperList[0].join(','), this.swiperList.split(','))
          this.updateLoading = true
          if (this.dialogTile === '新增商品') {
            savePointsMallGoods({
              action: 'add',
              detail: this.subForm
            })
              .then(res => {
                if (res.code === 200) {
                  this.queryDataList()
                  this.$message.success('新建成功')
                  this.showDialog = false
                }
              })
              .catch(() => {
                this.showDialog = false
              })
              .finally(() => {
                this.updateLoading = false
                this.showDialogLoading = false
              })
          } else {
            savePointsMallGoods({
              action: 'update',
              detail: this.subForm
            })
              .then(res => {
                if (res.code === 200) {
                  this.queryDataList()
                  this.$message.success('修改成功')
                  this.showDialog = false
                }
              })
              .catch(() => {
                this.showDialog = false
              })
              .finally(() => {
                this.updateLoading = false
                this.showDialogLoading = false
              })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.game-cover{
  margin-top: 20px;
}
  .page-description {
    font-size: 14px;
    color: #aaa;
    margin: 30px 0;
  }
  .header {
    font-size: 16px;
    padding: 0 0 20px 20px;
  }
  .formcontent{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .div{
      width: 200px;
      height: 200px;
      margin: 20px;
      border: 1px #DCDFE6 solid;
      position: relative;
    }
    .content{
      position: absolute;
      text-align: center;
      bottom: -28px;
      left: 0px;
      width: 100%;
    }
  }

  .ElectronicCommerce {
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        width: 210px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
    }
    .batchUpload{
      text-align: center;
      .icon-wrapper{
        .upload-icon{
          font-size: 100px;
          color: @primary;
        }
      }
      .batchUpload-tips{
        margin: 20px 0 10px;
      }
      .batchUpload-ctrl-area{
        margin-top: 30px;
        text-align: right;
      }
    }
    .coverUpload {
      display: flex;
      justify-content: center;
    }
    .ctrlWrapper {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
    .image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
</style>
