<template>
  <span>
    <input
      id="excel-upload-input"
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      :accept="accept"
      hidden
      @change="handleFileChange"
    >
    <el-button
      :type="button"
      style="margin-right: 20px"
      @click="$refs['excel-upload-input'].click()"
    >
      {{ text }}
    </el-button>
  </span>
</template>

<script>
import XLSX from 'xlsx'
export default {
  name: 'UploadExcel',
  props: {
    text: {
      type: String,
      default: ''
    },
    button: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      accept: '.xlsx, .xls'
    }
  },
  methods: {
    handleFileChange (e) {
      // console.log('wenje', e.target.files[0], this.$parent.getFile())
      this.$emit('getFile', e.target.files[0])
      const files = e.target.files
      const rawFile = files[0] // only use files[0]
      if (!rawFile) return
      this.$refs['excel-upload-input'].value = null // fix can't select the same excel
      const suffix = rawFile.name.split('.').pop()
      if (!this.accept.includes(suffix)) {
        return this.$message.error(`请选择${this.accept}文件`)
      }
      this.readerData(rawFile)
      this.getFile(rawFile)
    },
    readerData (rawFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'array' })
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet)
          this.generateData({ header, results })
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    getFile (files) {
      this.$emit('uploaded2', files)
    },
    generateData ({ header, results: list }) {
      this.$emit('uploaded', { header, list })
    },
    getHeaderRow (sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    }
  }
}
</script>

<style scoped>

</style>
