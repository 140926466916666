import request from '@/utils/request'

export function getDailyCheckinPrize (data) {
  return request({
    url: '/activity/getDailyCheckinPrize',
    data
  })
}
export function saveDailyCheckinPrize (data) {
  return request({
    url: '/activity/saveDailyCheckinPrize',
    data
  })
}
export function getDailyCheckinPeriod (data) {
  return request({
    url: '/activity/getDailyCheckinPeriod',
    data
  })
}
export function getDailyCheckinConfig (data) {
  return request({
    url: '/activity/getDailyCheckinConfig',
    data
  })
}
export function dailyCheckinType (data) {
  return request({
    url: '/activity/dailyCheckinType',
    data
  })
}
export function saveDailyCheckinConfig (data) {
  return request({
    url: '/activity/saveDailyCheckinConfig',
    data
  })
}
export function pointsExchangeGoodsList (data) {
  return request({
    url: '/pay/pointsExchangeGoodsList',
    data
  })
}
export function savePointsMallGoods (data) {
  return request({
    url: '/pay/savePointsMallGoods',
    data
  })
}
export function getPointsMallOrderList (data) {
  return request({
    url: '/pay/getPointsMallOrderList',
    data
  })
}
export function addPointsMallCDK (data) {
  return request({
    url: '/pay/addPointsMallCDK',
    data
  })
}
export function pointsMallGoodsType (data) {
  return request({
    url: '/pay/pointsMallGoodsType',
    data
  })
}
